import {useLocalstorage} from '../localStorage/store'
import {generateUUID} from '../uuid/util'
import {
  allmaJobsGetById,
  } from './api'
import type {AllmaJob, JobImageOptions, ThematicMapType} from '~/models/allmajob'
import {useContractsStore} from '~/use/contracts/store'
import type {MapImage} from '~/models/contract'
import {base64ImageSize, imageCompressor, imageToBase64} from '~/helpers/util'
import {useAxiosClient} from "~/use/axios/client";
import {AllmaJobApi} from "~/gen/openapi/sblService";

const { persist } = useLocalstorage()

const state = reactive({
  allmaJobsByProperty: persist<AllmaJob[]>('allmaJobsByProperty', []),
  ownAllmaJobs: persist<AllmaJob[]>('ownAllmaJobs', []),
  unusedAllmaJobs: persist<AllmaJob[]>('unusedAllmaJobs', []),
  thematicMapTypes: persist<ThematicMapType[]>('thematicMapTypes', []),
  listOfJobsToFetch: persist('listOfJobsToFetch', []),
  numberOfJobsFetching: persist('numberOfJobsFetching', 0),
  fetchingMapImages: persist('fetchingMapImages', []),
  fetchingAllmaJobsByProperty: persist<Boolean>('fetchingAllmaJobsByProperty', false),
  fetchingOwnAllmaJobs: persist<Boolean>('fetchingOwnAllmaJobs', false),
  fetchingUnusedAllmaJobs: persist<Boolean>('fetchingUnusedAllmaJobs', false),
  lastFetchedUnusedAllmaJobs: persist<number>('lastFetchedUnusedAllmaJobs', 0),
  lastFetchedThematicMapTypes: persist<number>('lastFetchedThematicMapTypes', 0),
})

const allmaJobsByProperty = computed((): AllmaJob[] => state.allmaJobsByProperty)
const ownAllmaJobs = computed((): AllmaJob[] => state.ownAllmaJobs)
const unusedAllmaJobs = computed((): AllmaJob[] => state.unusedAllmaJobs)
const fetchingAllmaJobsByProperty = computed(() => state.fetchingAllmaJobsByProperty)
const fetchingOwnAllmaJobs = computed(() => state.fetchingOwnAllmaJobs)
const fetchingUnusedAllmaJobs = computed(() => state.fetchingUnusedAllmaJobs)
const listOfJobsToFetch = computed((): string[] => state.listOfJobsToFetch)
const thematicMapTypes = computed((): ThematicMapType[] => state.thematicMapTypes)
const numberOfJobsFetching = computed((): number => state.numberOfJobsFetching)
const fetchingMapImages = computed((): MapImage[] => state.fetchingMapImages)
const lastFetchedUnusedAllmaJobs = computed((): number => state.lastFetchedUnusedAllmaJobs)
const lastFetchedThematicMapTypes = computed((): number => state.lastFetchedThematicMapTypes)

const clearAllmaJobs = (): void => {
  state.allmaJobsByProperty = []
  state.ownAllmaJobs = []
  state.listOfJobsToFetch = []
  state.numberOfJobsFetching = 0
  state.fetchingMapImages = []
}

const addToFetchList = (id: string): void => {
  if (!state.listOfJobsToFetch.find(j => j === id)) {
    state.listOfJobsToFetch.push(id)
  }
}

const removeFromFetchList = (id: string): void => {
  const existingJob = state.listOfJobsToFetch.find(a => a === id)
  if (existingJob) {
    state.listOfJobsToFetch.splice(state.listOfJobsToFetch.indexOf(existingJob), 1)
  }
}

const addToFetchListImage = (): void => {
  state.fetchingMapImages.push({
    Loading: true
  })
}

const removeFromFetchListImage = (): void => {
  state.fetchingMapImages.splice((state.fetchingMapImages.length - 1), 1)
}

const setNumberOfJobsFetching = (addValue: number): void => {
  state.numberOfJobsFetching += addValue
}

const setThematicMapTypes = (types: ThematicMapType[]): void => {
  state.thematicMapTypes = types
}

const loadAllmaJobsByProperty = async (propertyId: string, includeJobsWithoutProperty?: boolean): Promise<void> => {
  try {
    const { axiosClient } = useAxiosClient()
    const allmaJobApi = new AllmaJobApi(undefined, '', axiosClient.value)

    state.fetchingAllmaJobsByProperty = true
    const allmaJobs: AllmaJob[] = (await allmaJobApi.allmaJobGetAllmaJobsByProperty(propertyId))?.data
    state.allmaJobsByProperty = (includeJobsWithoutProperty ? allmaJobs : allmaJobs.filter((aj) => aj.PropertyId === propertyId))
  } catch (error) {
    throw new Error(error)
  } finally {
    state.fetchingAllmaJobsByProperty = false
  }
}

const loadAllmaJobById = async (params: unknown): Promise<AllmaJob> => {
  try {
    setNumberOfJobsFetching(1)
    const allmaJob: AllmaJob = await allmaJobsGetById(params)
    return allmaJob
  } catch (error) {
    throw new Error(error)
  } finally {
    setNumberOfJobsFetching(-1)
  }
}

const loadOwnAllmaJobs = async (): Promise<void> => {
  try {
    const { axiosClient } = useAxiosClient()
    const allmaJobApi = new AllmaJobApi(undefined, '', axiosClient.value)
    state.fetchingOwnAllmaJobs = true
    state.ownAllmaJobs = (await allmaJobApi.allmaJobGetOwnAllmaJobs())?.data
  } catch (error) {
    throw new Error(error)
  } finally {
    state.fetchingOwnAllmaJobs = false
  }
}

const loadUnusedAllmaJobs = async (force?: boolean): Promise<void> => {
  const { axiosClient } = useAxiosClient()
  const allmaJobApi = new AllmaJobApi(undefined, '', axiosClient.value)

  // If already fetched in last 5 min (5m * 60s * 1000ms = 300000), skip the fetch
  if (!force && lastFetchedUnusedAllmaJobs && lastFetchedUnusedAllmaJobs.value > Date.now() - 300000) {
    return
  }
  try {
    state.fetchingUnusedAllmaJobs = true
    state.unusedAllmaJobs = (await allmaJobApi.allmaJobGetAllUnusedAllmaJobs()).data
    state.lastFetchedUnusedAllmaJobs = Date.now()
  } catch (error) {
    throw new Error(error)
  } finally {
    state.fetchingUnusedAllmaJobs = false
  }
}

const loadThematicMapTypes = async (force?: boolean): Promise<void> => {
  if (!force &&
      state.thematicMapTypes.length &&
      lastFetchedThematicMapTypes &&
      lastFetchedThematicMapTypes.value > Date.now() - 1000 /*ms*/ * 60 /*seconds*/ * 60 /*minutes*/
  ) {
    return
  }
  try {
    const { axiosClient } = useAxiosClient()
    const allmaJobApi = new AllmaJobApi(undefined, '', axiosClient.value)

    const response: ThematicMapType[] = (await allmaJobApi.allmaJobListThematicMapTypes())?.data
    setThematicMapTypes(response)
    state.lastFetchedThematicMapTypes = Date.now()
  } catch (error) {
    console.error('Failed to load thematic map types', error)
    throw new Error(error)
  }
}

const fetchImages = async ({
  id,
  thematicMapId,
  withBuffer = true,
  zoomToBuffer = true,
  scale = 0
}: JobImageOptions): Promise<string> => {
  try {
    const options: JobImageOptions = {
      id,
      thematicMapId,
      withBuffer,
      zoomToBuffer,
      scale
    }
    const { axiosClient } = useAxiosClient()
    const allmaJobApi = new AllmaJobApi(undefined, '', axiosClient.value)
    const jobImage = (await allmaJobApi.allmaJobGetImage(options))?.data
    try {
      const file = await fetch(`data:${jobImage}`) // Base64 image to file
      const compressedFile = await imageCompressor(await file.blob(), 0.7) // Adjust second number up (max 1) for higher quality, or down (min 0.2) for lower
      return await imageToBase64(compressedFile)
    } catch (error) {
      throw new Error(error)
    }
  } catch (error) {
    throw new Error(error)
  }
}

const addMapImages = async (options: JobImageOptions): Promise<void> => {
  addToFetchListImage()
  try {
    const image = await fetchImages(options)
    const { addCurrentContractMapImages } = useContractsStore()
    addCurrentContractMapImages({
      Id: 'temp_' + generateUUID(),
      Name: 'import_oppdrag_' + options.id.toString() + '_' + options.thematicMapId + '.jpg',
      Size: base64ImageSize(image),
      Data: image
    })
    removeFromFetchListImage()
  } catch (error) {
    removeFromFetchListImage()
    console.error(`could not fetch default job image from allma. JobId ${options.id}`)
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAllmaJobsStore = () => ({
  allmaJobsByProperty,
  listOfJobsToFetch,
  fetchingMapImages,
  thematicMapTypes,
  numberOfJobsFetching,
  clearAllmaJobs,
  loadAllmaJobsByProperty,
  loadOwnAllmaJobs,
  loadUnusedAllmaJobs,
  loadAllmaJobById,
  loadThematicMapTypes,
  addToFetchList,
  removeFromFetchList,
  addMapImages,
  ownAllmaJobs,
  unusedAllmaJobs,
  fetchingAllmaJobsByProperty,
  fetchingOwnAllmaJobs,
  fetchingUnusedAllmaJobs,
})
